import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import PropTypes from "prop-types"
import Sidebar from "../containers/productos/sidebar"
import Section, { Row, Col } from "../components/UI/wrapper"
import queryString from "query-string"
import Heading from "../components/Heading"
import BoxLargeImage from "../components/box-large-image/layout-two"

const SeccionProductos = ({
  boxStyles,
  pageContext,
  location,
  ...restProps
}) => {
  const { sectionStyle } = restProps
  const query = location && queryString.parse(location.search)
  const data = useStaticQuery(graphql`
    {
      allPrismicProducto {
        edges {
          node {
            data {
              titulo {
                text
              }
              foto {
                fixed(width: 200, height: 200) {
                  ...GatsbyPrismicImageFixed
                }
              }
              contenido {
                text
              }
              marca {
                document {
                  ... on PrismicMarca {
                    data {
                      marca {
                        text
                      }
                    }
                  }
                }
              }
              categoria {
                document {
                  ... on PrismicCategoria {
                    data {
                      nombre {
                        text
                      }
                    }
                  }
                }
              }
              tags {
                document {
                  ... on PrismicTag {
                    data {
                      nombre {
                        text
                      }
                    }
                  }
                }
              }
            }
            uid
          }
        }
      }
    }
  `)
  let filteredProducts = []
  const productos = data.allPrismicProducto.edges || []
  filteredProducts = productos.filter(producto => {
    const { titulo, contenido, marca, categoria, tags } = producto.node.data
    return (
      titulo.text.toLowerCase().includes(query.busqueda) ||
      contenido.text.toLowerCase().includes(query.busqueda) ||
      marca.document.data.marca.text.toLowerCase().includes(query.busqueda) ||
      categoria.document.data.nombre.text
        .toLowerCase()
        .includes(query.busqueda) ||
      tags.document.data.nombre.text.toLowerCase().includes(query.busqueda)
    )
  })
  return (
    <>
      <SEO title="Productos Seggasa" />
      <main className="site-wrapper-reveal">
        <Section {...sectionStyle}>
          <Row>
            <Col lg={{ span: 4, order: 1 }} xs={{ span: 12, order: 2 }}>
              <Sidebar />
            </Col>
            <Col lg={{ span: 8, order: 2 }} xs={{ span: 12, order: 1 }}>
              <Row>
                <Col>
                  <Heading as="h4" content={`Busqueda de: ${query.busqueda}`} />
                  <Heading
                    as="h5"
                    content={`${filteredProducts.length} productos encontrados`}
                  />
                </Col>
              </Row>
              <Row>
                {filteredProducts.map((item, index) => (
                  <Col
                    lg={4}
                    md={6}
                    className="box-item"
                    key={`box-image-${index}`}
                  >
                    <BoxLargeImage
                      {...boxStyles}
                      imageSrc={item.node.data.foto.fixed.src}
                      btnText={item.node.data.titulo.text}
                      desc={item.node.data.marca.document.data.marca.text.replace(
                        /^\w/,
                        c => c.toUpperCase()
                      )}
                      path={`/productos/${item.node.uid}`}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Section>
      </main>
    </>
  )
}

SeccionProductos.propTypes = {
  sectionStyle: PropTypes.object,
  boxStyles: PropTypes.object,
}

SeccionProductos.defaultProps = {
  sectionStyle: {
    pt: "89px",
    pb: "100px",
    responsive: {
      medium: {
        pt: "72px",
        pb: "80px",
      },
      small: {
        pt: "53px",
        pb: "60px",
      },
    },
  },
  boxStyles: {
    headingStyle: {
      color: "#000",
    },
  },
}

export default SeccionProductos
